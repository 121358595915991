import React from 'react';
import { ContactConfirmationCont } from '../../components/styles/Contact/Contact.styled';

function ContactConfirmation() {
  return (
    <ContactConfirmationCont>
      <h1>Thanks!</h1>
      <h3>I'll be in touch with you shortly!</h3>
    </ContactConfirmationCont>
  );
}

export default ContactConfirmation;
